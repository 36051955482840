export default {

  init() {
    this.homeSlider();
    this.partnersSlider();
    this.projectsMore();
    this.paged = 1;
    this.videoPlay();
  },
  projectsMore() {
    let self = this;

    $(document).on('click', '.projects .btn-more', function (){
      let $this = $(this);
      let $spinner = $('.spinnerProjects');
      $this.hide();
      $spinner.css('display', 'inline-block');
      self.paged = self.paged+1;

      $.ajax({
        url: window.ajaxUrl,
        method: 'POST',
        data: {
          action: 'getProjects',
          paged: self.paged,
        },
        success: function (res) {
          console.log(res);
          if (res.success && res.data.projects) {
            $this.show();
            $spinner.hide();
            $('.projects .projectsList').append(res.data.projects)
          } else  {
            $spinner.hide();
          }
        },
      });
    })
  },
  homeSlider(){
      $('.homeSlider').slick({
        autoplay: true,
        prevArrow: $('.prev'),
        nextArrow: $('.next'),
        pauseOnHover: true,
      });
  },
  partnersSlider(){


    if (screen.width < 1000) {
      $('.partnersSlider').slick({
        arrows: false,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      });
    } else {
      $('.partnersSlider').slick({
        arrows: false,
        autoplay: true,
        slidesToShow: 5,
        slidesToScroll: 1,
      });
    }
  },
  videoPlay(){
    $('.click-for-video').click(function() {
      this.style.display = 'none';
      $('.video-item').css('display', 'block');
    });
  },

  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },

};
